import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import ReactMarkdown from 'react-markdown';

import styles from './FilterWidget.module.scss';

import { useTaggedArticles } from './FilterHelper';
import { getContenthubArticleFullSlug } from '../ContentHub/ContentHubHelper';
import Chevron from '../../SVGComponents/Chevron';
import Button from 'aphrodite-shared/ui/Button/Button';

const FilterWidget = ({ heading, intro }) => {
    const [activeArticle, setActiveArticle] = useState(null);

    const taggedArticles = useTaggedArticles();

    // set top article as active on mount
    useEffect(() => setActiveArticle(taggedArticles.allArticles.edges[0]), [
        taggedArticles.allArticles.edges
    ]);

    const context = useContext(IwContext);

    const openArticle = (article) => {
        context.openCovidFilterMobileModal(article);
    };

    const renderTags = (tags) => {
        tags = tags.filter((tag) =>
            ['loans', 'grants', 'businessRelief'].includes(tag.articleId)
        );
        return (
            <div className={styles.tags}>
                {tags.map(({ articleTag, articleId }) => {
                    return (
                        <div
                            key={articleId}
                            className={classnames(
                                styles.tag,
                                styles[articleId]
                            )}>
                            {articleTag}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderListOfArticles = () => {
        return taggedArticles.allArticles.edges.map((article) => {
            return (
                <button
                    key={article.node.articleTitle}
                    onClick={() => renderActiveArticle(article)}
                    data-ga-id={`${article.node.articleTitle}__expand`}
                    className={classnames(styles.article, {
                        [styles.activeListArticle]:
                            activeArticle &&
                            activeArticle.node.articleTitle ===
                                article.node.articleTitle
                    })}>
                    {renderTags(article.node.articleTags)}
                    <p data-ga-id={`${article.node.articleTitle}__expand`}>
                        {article.node.schemeTitle || article.node.articleTitle}
                    </p>
                    <Chevron className={styles.chevron} stroke={'#000'} />
                </button>
            );
        });
    };

    const renderActiveArticle = (article) => {
        if (window !== undefined && window.innerWidth >= 800) {
            setActiveArticle(article);
        } else {
            // mobile show modal
            openArticle(article);
        }
    };

    const renderArticleSnippet = () => {
        return (
            <>
                <h3>
                    {activeArticle.node.schemeTitle ||
                        activeArticle.node.articleTitle}
                </h3>
                {activeArticle.node.blogSnippet && (
                    <ReactMarkdown
                        source={activeArticle.node.blogSnippet.blogSnippet}
                    />
                )}
                <a
                    href={getContenthubArticleFullSlug(
                        activeArticle.node.categoryTag.categoryTag,
                        activeArticle.node.slug
                    )}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Button
                        dataGaId={`${activeArticle.node.articleTitle}__readmore`}
                        buttonClass={styles.readMore}>
                        Read more
                    </Button>
                </a>
            </>
        );
    };

    return (
        <section className={styles.FilterWidget}>
            <div className={styles.container}>
                <div className={styles.top}>
                    <h2>{heading}</h2>
                    <ReactMarkdown source={intro} />
                </div>
                <div className={styles.bottom}>
                    <div className={styles.articleList}>
                        {renderListOfArticles()}
                    </div>
                    <div className={styles.desktopContent}>
                        {activeArticle && renderArticleSnippet()}
                    </div>
                </div>
                <div className={styles.scrollFlex}>
                    <div className={styles.scrollInfo}>
                        <p>scroll down for more options</p>
                    </div>
                    <div className={styles.cover}></div>
                </div>
            </div>
        </section>
    );
};

export default FilterWidget;
