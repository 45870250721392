import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './CovidInformation.module.scss';

const CovidInformation = ({ data }) => {
    return (
        <section className={styles.CovidInformation}>
            <div className={styles.container}>
                <div className={styles.info}>
                    <ReactMarkdown
                        source={data.information}
                        escapeHtml={false}
                    />
                </div>
                <hr />
                <div className={styles.points}>
                    <ul>
                        <li
                            dangerouslySetInnerHTML={{
                                __html: data.pointOne
                            }}></li>
                        <li
                            dangerouslySetInnerHTML={{
                                __html: data.pointTwo
                            }}></li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default CovidInformation;
