import { useStaticQuery, graphql } from 'gatsby';

export const useCovidArticles = () => {
    const covidHubData = useStaticQuery(graphql`
        query covidHubDataQuery {
            financialSupport: allContentfulBlogArticle(
                filter: {
                    articleTags: {
                        elemMatch: { articleId: { eq: "financialSupport" } }
                    }
                }
            ) {
                edges {
                    node {
                        ...articleCategory
                    }
                }
            }
            tipsAndTools: allContentfulBlogArticle(
                filter: {
                    articleTags: {
                        elemMatch: { articleId: { eq: "tipsAndTools" } }
                    }
                }
            ) {
                edges {
                    node {
                        ...articleCategory
                    }
                }
            }
            whatWeAreDoing: allContentfulBlogArticle(
                filter: {
                    articleTags: {
                        elemMatch: { articleId: { eq: "whatWeAreDoing" } }
                    }
                }
            ) {
                edges {
                    node {
                        ...articleCategory
                    }
                }
            }
        }

        fragment articleCategory on ContentfulBlogArticle {
            articleTitle
            categoryTag {
                categoryTag
            }
            articleTags {
                articleTag
                articleId
            }
            slug
            articleHeaderImage {
                fluid(maxWidth: 600, quality: 85) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
        }
    `);
    return covidHubData;
};
