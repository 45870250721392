import React, { useState } from 'react';
import classnames from 'classnames';
import CTABox from '../../../../components/CTABox/CTABox';
import styles from './HubSections.module.scss';

import { useCovidArticles } from '../CovidHubHelper';
import { Element, Link } from 'react-scroll';
import FilterWidget from '../../../../components/FilterWidget/FilterWidget';
import HubSection from '../HubSection/HubSection';
import {
    BasicArch,
    BasicSquare,
    BasicDoughnut,
    WedgedArch
} from '../../../../SVGComponents/shapes';

const HubSections = ({
    heading,
    links,
    sections,
    filterHeading,
    filterIntro,
    cta
}) => {
    let [activeSection, setActiveSection] = useState(0);
    const covidArticles = useCovidArticles();

    const hubSectionContent = sections.map((hubSection) => {
        const listOfFeaturedArticles = [];

        for (let i = 0; i < hubSection.featuredArticles.length; i++) {
            listOfFeaturedArticles.push(
                hubSection.featuredArticles[i].articleTitle
            );
        }

        return {
            link: hubSection.sectionTag.articleId,
            title: hubSection.sectionTitle,
            subtitle: hubSection.sectionLongSubtitle.sectionLongSubtitle,
            description: hubSection.sectionDescription,
            featuredArticles: hubSection.featuredArticles,
            otherArticles: (function() {
                const removedFeaturedArticles = covidArticles[
                    hubSection.sectionTag.articleId
                ].edges.filter((article) => {
                    return !listOfFeaturedArticles.includes(
                        article.node.articleTitle
                    );
                });
                return removedFeaturedArticles;
            })()
        };
    });

    const renderLinks = () => {
        return (
            <ul className={styles.articleTags}>
                {links.map((link, i) => (
                    <Link
                        offset={-100}
                        to={link.articleId}
                        duration={500}
                        smooth={true}
                        className={classnames(styles.articleTag, {
                            [styles.active]: i === activeSection
                        })}
                        onClick={() => setActiveSection(i)}>
                        <li>
                            <a
                                href={`/#${link.articleId}`}
                                data-ga-id={`covidhub_nav__${link.articleId}`}>
                                {link.articleTag}
                            </a>
                        </li>
                    </Link>
                ))}
            </ul>
        );
    };

    const renderArticleSections = () => {
        return hubSectionContent.map((hubSection) => (
            <Element name={hubSection.link}>
                <HubSection
                    title={hubSection.title}
                    subtitle={hubSection.subtitle}
                    description={hubSection.description}
                    featuredArticles={hubSection.featuredArticles}
                    carouselData={hubSection.otherArticles}
                />
            </Element>
        ));
    };

    return (
        <>
            <BasicArch className={styles.basicArch} />
            <BasicSquare
                color="denim65"
                size="lg"
                className={styles.basicSquare}
            />
            <BasicArch color="denim65" className={styles.basicArchBlue} />
            <BasicDoughnut color="london" className={styles.blueDoughnut} />
            <BasicDoughnut
                color="denim45"
                className={styles.darkBlueDoughnut}
            />
            <WedgedArch
                color="denim30"
                size="sm"
                className={styles.wedgedArch}
            />
            <section className={styles.HubSections}>
                <div className={styles.container}>
                    <h1>{heading}</h1>
                    {renderLinks()}
                    <Element name="bestSupport">
                        <FilterWidget
                            heading={filterHeading}
                            intro={filterIntro.filterWidgetIntroLong}
                        />
                    </Element>
                    {renderArticleSections()}
                </div>
                <CTABox
                    content={cta}
                    className={styles.CTABox}
                    isCovidHub={true}
                />
            </section>
        </>
    );
};

export default HubSections;
