import React from 'react';
import { graphql } from 'gatsby';

import styles from './CovidHub.module.scss';
import MetaData from '../../../components/Layout/MetaData';
import NavBar from '../../../../../aphrodite-uk/src/components/NavBar/NavBar';
import CovidInformation from '../../../components/ContentHub/CategoryPage/CovidInformation/CovidInformation';
import HubSections from './HubSections/HubSections';
import PressLogos from '../../../components/Press/PressLogos/PressLogos';
import Footer from '../../../components/Footer/Footer';

const CovidHub = ({ data }) => {
    const { contentfulPageCovidHub: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping,
        url,
        covidInformation,
        heading,
        sectionLinks,
        hubSections,
        filterWidgetHeading,
        filterWidgetIntroLong,
        cta
    } = pageData;

    return (
        <div className={styles.CovidHub}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar />
            <CovidInformation data={covidInformation} />
            <HubSections
                heading={heading}
                links={sectionLinks}
                sections={hubSections}
                filterHeading={filterWidgetHeading}
                filterIntro={filterWidgetIntroLong}
                cta={cta}
            />
            <PressLogos />
            <Footer />
        </div>
    );
};

export default CovidHub;

export const query = graphql`
    query CovidHubPageQuery($id: String!) {
        contentfulPageCovidHub(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            covidInformation {
                information
                pointOne
                pointTwo
            }
            heading
            sectionLinks {
                articleId
                articleTag
            }
            filterWidgetHeading
            filterWidgetIntroLong {
                filterWidgetIntroLong
            }
            hubSections {
                sectionTitle
                sectionLongSubtitle {
                    sectionLongSubtitle
                }
                sectionDescription
                sectionTag {
                    articleTag
                    articleId
                }
                featuredArticles {
                    articleTitle
                    slug
                    categoryTag {
                        categoryTag
                    }
                    articleHeaderImage {
                        fluid(maxWidth: 800, quality: 85) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
            trustpilotRating {
                trustpilotRating
                copy
            }
            cta {
                copy {
                    copy
                }
                callToAction {
                    url
                    buttonText
                    dataGaId
                    buttonText
                }
                image {
                    fluid(maxWidth: 1100, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
        }
    }
`;
