import { useStaticQuery, graphql } from 'gatsby';

export const useTaggedArticles = () => {
    const taggedArticlesData = useStaticQuery(graphql`
        query taggedArticlesDataQuery {
            allArticles: allContentfulBlogArticle(
                filter: {
                    articleTags: {
                        elemMatch: {
                            articleId: {
                                regex: "/(loans|grants|businessRelief)/"
                            }
                        }
                    }
                }
            ) {
                edges {
                    node {
                        ...taggedArticle
                    }
                }
            }
            loans: allContentfulBlogArticle(
                filter: {
                    articleTags: { elemMatch: { articleId: { eq: "loans" } } }
                }
            ) {
                edges {
                    node {
                        ...taggedArticle
                    }
                }
            }
            grants: allContentfulBlogArticle(
                filter: {
                    articleTags: { elemMatch: { articleId: { eq: "grants" } } }
                }
            ) {
                edges {
                    node {
                        ...taggedArticle
                    }
                }
            }
            businessRelief: allContentfulBlogArticle(
                filter: {
                    articleTags: {
                        elemMatch: { articleId: { eq: "businessRelief" } }
                    }
                }
            ) {
                edges {
                    node {
                        ...taggedArticle
                    }
                }
            }
        }

        fragment taggedArticle on ContentfulBlogArticle {
            articleTitle
            schemeTitle
            categoryTag {
                categoryTag
            }
            slug
            articleTags {
                articleTag
                articleId
            }
            blogSnippet {
                blogSnippet
            }
        }
    `);
    return taggedArticlesData;
};
