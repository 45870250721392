import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import classnames from 'classnames';

import styles from './HubSection.module.scss';

import { getContenthubArticleFullSlug } from '../../../../components/ContentHub/ContentHubHelper';
import IwLink from '../../../../components/IwLink/IwLink';
import Chevron from '../../../../SVGComponents/Chevron';

const HubSection = ({
    title,
    subtitle,
    description,
    featuredArticles,
    carouselData
}) => {
    const mainArticle = featuredArticles[0];
    const otherArticles = featuredArticles.slice(1);

    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        rows: 4,
        slidesPerRow: 1,
        variableWidth: true,
        nextArrow: <More />,
        prevArrow: <Back />
    };

    return (
        <section className={styles.HubSection}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.subtitle}>{subtitle}</p>
            <div className={styles.container}>
                <div className={styles.mainArticle}>
                    <IwLink
                        to={getContenthubArticleFullSlug(
                            mainArticle.categoryTag.categoryTag,
                            mainArticle.slug
                        )}>
                        <Img
                            className={styles.mainImage}
                            fluid={mainArticle.articleHeaderImage.fluid}
                        />
                        <h3>{mainArticle.articleTitle}</h3>
                    </IwLink>
                    <p>{description}</p>
                </div>

                <div className={styles.otherArticles}>
                    <div className={styles.featuredArticles}>
                        {otherArticles.map((article) => (
                            <div className={styles.article}>
                                <IwLink
                                    to={getContenthubArticleFullSlug(
                                        article.categoryTag.categoryTag,
                                        article.slug
                                    )}>
                                    <Img
                                        className={styles.featuredImage}
                                        fluid={article.articleHeaderImage.fluid}
                                    />
                                    <h4>{article.articleTitle}</h4>
                                </IwLink>
                            </div>
                        ))}
                    </div>

                    <hr />

                    <div className={styles.carouselArticles}>
                        <Slider {...sliderSettings}>
                            {carouselData.map((article) => (
                                <IwLink
                                    to={getContenthubArticleFullSlug(
                                        article.node.categoryTag.categoryTag,
                                        article.node.slug
                                    )}>
                                    <div className={styles.carouselArticle}>
                                        <div className={styles.imageContainer}>
                                            <Img
                                                className={
                                                    styles.carouselArticleImage
                                                }
                                                fluid={
                                                    article.node
                                                        .articleHeaderImage
                                                        .fluid
                                                }
                                            />
                                        </div>
                                        <h5>{article.node.articleTitle}</h5>
                                    </div>
                                </IwLink>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

const More = ({ className, onClick }) => {
    return (
        <>
            <div
                className={classnames(
                    className,
                    styles.more,
                    styles.resetArrow
                )}
                onClick={onClick}>
                More
            </div>
            <Chevron
                className={classnames(
                    className,
                    styles.moreChevron,
                    styles.resetArrow
                )}
                onClick={onClick}
                stroke={'#246AC1'}
            />
        </>
    );
};

const Back = ({ className, onClick }) => {
    return (
        <>
            <div
                className={classnames(
                    className,
                    styles.back,
                    styles.resetArrow
                )}
                onClick={onClick}>
                Back
            </div>
            <Chevron
                className={classnames(
                    className,
                    styles.backChevron,
                    styles.resetArrow
                )}
                onClick={onClick}
                stroke={'#246AC1'}
            />
        </>
    );
};

export default HubSection;
